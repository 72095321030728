import dayjs from "dayjs";
import { getEnvVar } from "./envUtils";

export function generateHtml(htmlTemplate, params) {
  let html = htmlTemplate;
  const backendURL = getEnvVar("BACKEND_API_URL") || "";
  const adminFrontendURL = getEnvVar("ADMIN_FRONTEND_URL") || window.origin;

  html = html.replaceAll("{{title}}", params.title);
  let tagsHtml = "";
  for (let tag of params?.tags || []) {
    let tagHtml = `<div class="category">${tag}</div>`;
    tagsHtml += tagHtml;
  }
  html = html.replaceAll("{{tags}}", tagsHtml);
  const createdDate = new Date();
  const date = dayjs(createdDate).format("DD MMM YYYY , hh:mm A").toString();
  html = html.replaceAll("{{createdDateTime}}", date);
  html = html.replaceAll("{{blogContent}}", params.content);
  if (params.image_url !== "") {
    html = html.replaceAll(
      "{{imageContent}}",
      `
            <div class="dynamicImage">
                <img src="${params.image_url}" />
            </div>
        `
    );
  } else {
    html = html.replaceAll("{{imageContent}}", ``);
  }

  const faviconURL = `${adminFrontendURL}/images/favicon.ico`;
  html = html.replaceAll("{{faviconURL}}", faviconURL);

  const logoURL = `${adminFrontendURL}/images/ourlipi_logo.svg`;
  html = html.replaceAll("{{logoURL}}", logoURL);

  html = html.replaceAll("{{blogDescription}}", params.description);
  html = html.replaceAll(
    "{{author}}",
    params.author ? params.author : "Anonymous"
  );
  html = html.replaceAll("{{backendURL}}", backendURL);
  html = html.replaceAll("{{adminFrontendURL}}", adminFrontendURL);
  return html;
}
