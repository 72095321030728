const { dynamoDocClient } = require("./dynamoClient");

async function getAPIStats(currentRows = [], lastItem) {
    return [];
    let params = {
        TableName: "apiStats",
    };
    if (lastItem) {
        params.ExclusiveStartKey = { id: lastItem };
    }
    const data = await dynamoDocClient.scan(params).promise();
    console.log("Dynamo DB List API Stats - Success", data);
    currentRows = currentRows.concat(data.Items);
    if (data.LastEvaluatedKey) {
        return getAPIStats(currentRows, data.LastEvaluatedKey?.id);
    }
    return currentRows;
}



module.exports = {
    getAPIStats,
};