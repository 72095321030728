import { useReducer } from "react";

function blogReducer(state, action) {
  if (action.type === "update_title") {
    return { ...state, title: action.payload };
  } else if (action.type === "update_author") {
    return { ...state, author: action.payload };
  } else if (action.type === "update_description") {
    return { ...state, description: action.payload };
  } else if (action.type === "update_content") {
    return { ...state, content: action.payload };
  } else if (action.type === "update_image_url") {
    return { ...state, image_url: action.payload };
  } else if (action.type === "update_tags") {
    return { ...state, tags: action.payload };
  } else if (action.type === "update_category") {
    return { ...state, category: action.payload };
  } else if (action.type === "update_sub_category") {
    return { ...state, sub_category: action.payload };
  } else if (action.type === "update_whole_blog") {
    return { ...state, ...(action.payload || {}) };
  } else {
    throw Error("Unknown action.");
  }
}

const initialState = {
  title: "",
  author: "",
  description: "",
  content: "",
  image_url: "",
  category: "",
  sub_category: "",
  tags: [],
  createdAt: "",
};

const useBlogReducer = () => {
  const [state, dispatch] = useReducer(blogReducer, initialState);
  const updateBlog = (key, value) => {
    dispatch({ type: `update_${key}`, payload: value });
  };

  return { state, updateBlog };
};

export default useBlogReducer;
