import * as React from "react";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { DarkMode, LightMode, LogoutTwoTone } from "@mui/icons-material";
import getCustomTheme from "../Theme/getCustomTheme";
import { Divider, Link, Tooltip } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import logo from "./../images/ourlipi_logo.svg";
import { hash } from "../Pages/Login/constants";


export function Copyright(props) {
  return (
    <>
      <Divider />
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
        sx={{ py: 2 }}
      >
        {"Copyright © "}
        <Link color="inherit" href="#">
          Ourlipi
        </Link>
        {" 2024."}
      </Typography>
    </>
  );
}

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const getDefaultTheme = (darkPalette) =>
  createTheme(getCustomTheme(darkPalette ? "dark" : "light"));

export default function Layout() {
  const [darkPalette, setDarkPalette] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage("loggedIn", "");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isLoggedIn !== hash) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  const logout = () => {
    setIsLoggedIn("");
    navigate("/login");
  };

  const togglePalette = () => {
    setDarkPalette(!darkPalette);
  };

  return (
    <ThemeProvider theme={getDefaultTheme(darkPalette)}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar
            disableGutters
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="img"
              noWrap
              sx={{ height: "60px", width: "auto" }}
              src={logo}
            />
            <Box>
              <IconButton
                color="inherit"
                onClick={togglePalette}
                aria-label="Theme toggle button"
              >
                {darkPalette ? <LightMode /> : <DarkMode />}
              </IconButton>
              {isLoggedIn ? (
                <Tooltip title={"Logout"}>
                  <IconButton
                    color="inherit"
                    aria-label="Logoff button"
                    onClick={logout}
                  >
                    <LogoutTwoTone />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Outlet />
          <Copyright />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
