import * as React from "react";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { DarkMode, LightMode, LogoutTwoTone } from "@mui/icons-material";
import getCustomTheme from "../Theme/getCustomTheme";
import { Button, Divider, Link, Tooltip } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import logo from "./../images/ourlipi_logo.svg";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InventoryIcon from "@mui/icons-material/Inventory";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { hash } from "../Pages/Login/constants";

export function Copyright(props) {
  return (
    <>
      <Divider />
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
        sx={{ py: 2 }}
      >
        {"Copyright © "}
        <Link color="inherit" href="#">
          Ourlipi
        </Link>
        {" 2024."}
      </Typography>
    </>
  );
}

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  minWidth: "100%",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const getDefaultTheme = (darkPalette) =>
  createTheme(getCustomTheme(darkPalette ? "dark" : "light"));

const sideBarMenu = [
  { label: "Control Center", href: "/", icon: SettingsApplicationsIcon },
  { label: "Manage Blogs", href: "/manage-blogs", icon: InventoryIcon },
  { label: "Create Blog", href: "/create-blog", icon: NoteAddIcon },
  // { label: "Stats", href: "/dashboard", icon: DashboardIcon },
];

export default function Layout() {
  const navigate = useNavigate();
  const [darkPalette, setDarkPalette] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage("loggedIn", "");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  React.useEffect(() => {
    if (isLoggedIn !== hash) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  const logout = () => {
    setIsLoggedIn("");
    navigate("/login");
  };

  const togglePalette = () => {
    setDarkPalette(!darkPalette);
  };
  const drawerWidth = 240;

  const drawerContent = (
    <>
      <Toolbar />
      <Box sx={{ overflow: "auto", height: "100%" }}>
        <List>
          {sideBarMenu.map(({ label, href, icon: Icon }, index) => (
            <ListItem
              key={label}
              disablePadding
              onClick={() => navigate(href, { replace: true, state: {} })}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );

  return (
    <ThemeProvider theme={getDefaultTheme(darkPalette)}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar
            disableGutters
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ padding: "1rem", margin: 0, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                component="img"
                noWrap
                sx={{ height: "60px", width: "auto" }}
                src={logo}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  window.open("https://ourlipi.com");
                }}
              >
                Go to Ourlipi
              </Button>
              <IconButton
                color="inherit"
                onClick={togglePalette}
                aria-label="Theme toggle button"
              >
                {darkPalette ? <LightMode /> : <DarkMode />}
              </IconButton>
              {isLoggedIn ? (
                <Tooltip title={"Logout"}>
                  <IconButton
                    color="inherit"
                    aria-label="Logoff button"
                    onClick={logout}
                  >
                    <LogoutTwoTone />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawerContent}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawerContent}
          </Drawer>
        </Box>
        {/* <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          {drawerContent}
        </Drawer> */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            minWidth: "320px",
          }}
        >
          <Toolbar />
          <Outlet />
          <Copyright />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
