const dayjs = require("dayjs");


const getPreviousFiveDayData = (array, consideredAttribute = "createdAt", multiplyBy1000 = false) => {
    const xAxis = [];
    const yAxis = [];
    const startTime = dayjs().startOf('day').toDate().getTime();
    const endTime = dayjs().endOf('day').toDate().getTime();
    const oneDayInMilliSeconds = 24 * 60 * 60 * 1000;
    for (let i = 4; i >= 0; i--) {
        const diff = oneDayInMilliSeconds * i;
        let startTimeForCurrentRow = startTime - diff;
        let endTimeForCurrentRow = endTime - diff;
        const total = array.filter((x) => {
            const timeToCompare = multiplyBy1000 ? x[consideredAttribute] * 1000 : x[consideredAttribute];
            if (timeToCompare >= startTimeForCurrentRow
                && timeToCompare <= endTimeForCurrentRow) {
                return x;
            }
        });
        yAxis.push(total.length);
        xAxis.push(dayjs(startTimeForCurrentRow).format("D/M/YY").toString());
    }
    return { xAxis, yAxis };
}


const getAPIHits = (items) => {
    const blogHits = items.filter((api) => {
        const macthed = api?.endpoint?.match("/api/v1/blog/+");
        if (
            api.endpoint === "/api/v1/blog/create" ||
            api.endpoint === "/api/v1/blog/update" ||
            api.endpoint ===
            "/blog/vendor/phpunit/phpunit/src/Util/PHP/eval-stdin.php"
        ) {
            return;
        }
        if (macthed) {
            return api;
        }
    });
    const homeHits = items.filter((api) => {
        const macthed = api?.endpoint?.match("/home");
        if (macthed) {
            return api;
        }
    });
    return { blogHits, homeHits }
}


const filterTodayList = (
    array,
    filters,
    attributeToConsider = "createdAt",
    shouldMultiplyBy1000 = true
) => {
    const filteredArray = array.filter((entry) => {
        const startOfTheDayInMilliSeconds = dayjs(filters.date)
            .startOf("day")
            .toDate()
            .getTime();
        const endOfTheDayInMilliSeconds = dayjs(filters.date).endOf("day").toDate().getTime();
        const time = shouldMultiplyBy1000
            ? entry[attributeToConsider] * 1000
            : entry[attributeToConsider];
        if (
            time >= startOfTheDayInMilliSeconds &&
            time <= endOfTheDayInMilliSeconds
        ) {
            return entry;
        }
    });
    return filteredArray;
};


const getMostLookedBlogs = (
    blogs
) => {
    const most = new Map();
    blogs.map((api) => {
        const url = api.endpoint?.split("blog/");
        let title = "";
        if (url && url.length === 2) {
            title = url[1];
        }
        if (!title) {
            return;
        }
        const count = most.get(title);
        if (count) {
            most.set(title, count + 1);
        } else {
            most.set(title, 1);
        }
    });
    let mostArray = Array.from(most, ([name, value]) => ({
        title: name,
        count: value,
    }));
    mostArray = mostArray.sort((a, b) => b.count - a.count);
    return mostArray;
};

module.exports = {
    getPreviousFiveDayData,
    getAPIHits,
    filterTodayList,
    getMostLookedBlogs
}