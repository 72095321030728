import React, { useEffect, useMemo } from "react";
import { useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Snackbar,
  SnackbarContent,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { listBlogs } from "../../dynamoDB/recordUtils";
import { gray } from "../../Theme/getCustomTheme";
import ModeIcon from "@mui/icons-material/Mode";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  deleteDraftBlog,
  listDraftBlogs,
} from "../../dynamoDB/draftRecordUtil";
import { DeleteOutline } from "@mui/icons-material";

const IMAGE_PLACEHOLDER =
  "https://res.cloudinary.com/drbdkvgzp/image/upload/aabmacszkzhmzek8pjyg.jpg";

export default function ManageBlogs() {
  const theme = useTheme();
  const [blogs, setBlogs] = useState([]);
  const [draftBlogs, setDraftBlogs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showDrafts, setShowDrafts] = useState(true);
  const [responseMessage, setResponseMessage] = useState(
    "Blog Deleted Successfully."
  );
  const [type, setType] = useState("success");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [snackState, setSnackState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = snackState;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initializeBlogsList = async () => {
    if (showDrafts) {
      const draftBlogsResponse = await listDraftBlogs();
      setDraftBlogs(
        draftBlogsResponse?.sort(
          (blogA, blogB) => blogB.createdAt - blogA.createdAt
        )
      );
      setIsLoading(false);
    } else {
      const blogsResponse = await listBlogs();
      setBlogs(
        blogsResponse?.sort((blogA, blogB) => blogB.createdAt - blogA.createdAt)
      );
      setIsLoading(false);
    }
  };
  const handleClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  useEffect(() => {
    setIsLoading(true);
    initializeBlogsList();
  }, [showDrafts]);

  const handleDelete = async (blog) => {
    if (process.env.NODE_ENV === "production") {
      setIsSubmitting(true);
      try {
        await deleteDraftBlog(blog.url);
        setSnackState({ ...snackState, open: true });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (e) {
        setType("failure");
        setResponseMessage("Failed to delete draft!");
        setSnackState({ ...snackState, open: true });
        setIsSubmitting(false);
      }
    }
  };

  const filteredBlogs = useMemo(() => {
    let list = showDrafts ? draftBlogs : blogs;
    if (searchText) {
      list = list.filter((blog) =>
        blog?.title?.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return list;
  }, [searchText, blogs, showDrafts, draftBlogs]);

  const handleEdit = (blog) => {
    navigate("/edit-blog", {
      state: {
        blog: showDrafts
          ? { ...blog, url: undefined, draftUrl: blog.url }
          : blog,
      },
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column" }}
          elevation={24}
        >
          <Grid container spacing={3} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Typography
                sx={{ textAlign: "center" }}
                fontSize={24}
                fontWeight={900}
              >
                Ourlipi Blogs
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              sx={{ position: "sticky", top: "45px", zIndex: 2 }}
            >
              <OutlinedInput
                fullWidth
                value={searchText}
                placeholder="Filter blogs by title.."
                onChange={(e) => setSearchText(e.target.value)}
                sx={{ background: theme.palette.background.paper }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search text"
                      onClick={() => setSearchText("")}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ position: "sticky", top: "45px", zIndex: 2 }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showDrafts}
                      onChange={() => {
                        setShowDrafts(!showDrafts);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Show Drafts"
                />
              </FormGroup>
            </Grid>
            <Grid item container xs={12} spacing={3}>
              {isLoading ? (
                <Container
                  sx={{
                    margin: "2rem",
                    padding: "2rem",
                    textAlign: "center",
                  }}
                >
                  <CircularProgress />
                </Container>
              ) : (
                filteredBlogs.map((blog, index) => (
                  <Grid key={blog.title} item lg={4} md={6} xs={12}>
                    <Card>
                      <CardMedia
                        sx={{ height: 200 }}
                        image={
                          blog.imageURL !== "EMPTY"
                            ? blog.imageURL
                            : IMAGE_PLACEHOLDER
                        }
                        title={blog.title}
                      >
                        <CardContent
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            padding: "0.5rem",
                            paddingBottom: "0.5rem !important",
                            background: `linear-gradient(to bottom, transparent 0%, ${gray[900]}BB 70%, ${gray[900]} 100%)`,
                          }}
                        >
                          <IconButton
                            disabled={isSubmitting}
                            sx={{
                              background: "white",
                              borderRadius: "10px",
                              transition: "all ease 200ms",
                              border: "2px solid transparent",
                              color: gray[900],
                              "&:hover": {
                                boxShadow: "0 0 20px #0004",
                                border: "2px solid white",
                                transform: "scale(0.96)",
                                background: gray[900],
                                color: "white",
                              },
                            }}
                          >
                            <ModeIcon
                              sx={{ display: "inline-flex" }}
                              onClick={() => handleEdit(blog)}
                            />
                          </IconButton>
                          {showDrafts && (
                            <IconButton
                              disabled={isSubmitting}
                              sx={{
                                background: "white",
                                borderRadius: "10px",
                                transition: "all ease 200ms",
                                border: "2px solid transparent",
                                color: gray[900],
                                "&:hover": {
                                  boxShadow: "0 0 20px #0004",
                                  border: "2px solid white",
                                  transform: "scale(0.96)",
                                  background: gray[900],
                                  color: "white",
                                },
                              }}
                            >
                              <DeleteOutline
                                sx={{
                                  display: "inline-flex",
                                }}
                                onClick={() => handleDelete(blog)}
                              />
                            </IconButton>
                          )}

                          <Typography
                            variant="body2"
                            color="white"
                            width={"100%"}
                          >
                            {blog.title}
                          </Typography>
                        </CardContent>
                      </CardMedia>
                    </Card>
                  </Grid>
                ))
              )}
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                key={vertical + horizontal}
                autoHideDuration={3000}
                ContentProps={{
                  classes: {
                    root: {
                      background: "green",
                      color: "white",
                    },
                  },
                }}
              >
                <SnackbarContent
                  message={responseMessage}
                  style={{
                    background: type === "success" ? "#b3eda0" : "#f85252",
                  }}
                />
              </Snackbar>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}
