import { HmacSHA512 } from 'crypto-js';
import { getEnvVar } from './envUtils';
import Base64 from 'crypto-js/enc-base64';

export const createSHA512Hash = (inputStringToBeHashed) => {
    const SECRET = getEnvVar('HASHING_SECRET') || '';
    if (!SECRET) {
        console.error(`HASHING_SECRET is empty!!!`);
        throw new Error('Failed to generate HASH!!!');
    }
    return Base64.stringify(HmacSHA512(inputStringToBeHashed, SECRET));
};
