const { dynamoDocClient } = require("./dynamoClient");


async function getBlog(title) {
    var params = {
        TableName: "blog",
        Key: { title: title },
    };
    const data = await dynamoDocClient.get(params).promise();
    console.log("Dynamo DB Get Blog - Success", data.Item);
    return data.Item;
}

async function listBlogs(currentRows = [], lastItem) {
    let params = {
        TableName: "blog",
    };
    if (lastItem) {
        params.ExclusiveStartKey = { id: lastItem };
    }
    const data = await dynamoDocClient.scan(params).promise();
    console.log("Dynamo DB List Blog - Success", data);
    currentRows = currentRows.concat(data.Items);
    if (data.LastEvaluatedKey) {
        return listBlogs(currentRows, data.LastEvaluatedKey?.id);
    }
    return currentRows;
}


module.exports = {
    getBlog,
    listBlogs,
};