import React from "react";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  Paper,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import loremIpsum from "./lorem-ipsum";
import useBlogReducer from "./Blog.reducer";
import { generateHtml } from "../../javascript/htmlGenerator";
import { createBlog } from "../../javascript/createBlog";
import { getEnvVar } from "../../javascript/envUtils";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import { gray } from "../../Theme/getCustomTheme";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteDraftBlog,
  getDraftBlog,
  storeDraftBlog,
} from "../../dynamoDB/draftRecordUtil";
import { Select, MenuItem } from "@mui/material";
import data from "./data.json";

const QUILL_FORMATS = [
  "background",
  "direction",
  "align",
  "script",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "code",
  "color",
  "clean",
];

const QUILL_MODULES = {
  toolbar: {
    container: [
      [{ header: [2, 3, 4, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ direction: "rtl" }, { align: [] }],
      [{ color: [] }, { background: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "code"],
      ["clean"],
    ],
  },
  clipboard: {
    matchVisual: true,
  },
};

export const FormGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& .quill": {
    boxShadow:
      theme.palette.mode === "dark"
        ? "0px 2px 2px rgb(0, 0, 0)"
        : "0px 1px 1px rgba(0, 0, 0, 0.1)",
  },
  "& .ql-toolbar, .ql-container": {
    border: "1px solid",
    borderColor: theme.palette.mode === "dark" ? gray[700] : gray[200],
  },
  "& .ql-toolbar": {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  "& .ql-container, .quill": {
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
}));

export default function BlogForm({ editMode }) {
  const {
    state: {
      title,
      author,
      description,
      content,
      image_url,
      tags,
      category,
      sub_category,
      createdAt,
      url,
    } = {},
    updateBlog,
  } = useBlogReducer();
  const location = useLocation();
  const navigate = useNavigate();

  const [titleError, setTitleError] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [previewHtml, setPreviewHtml] = useState("");
  const [responseMessage, setResponseMessage] = useState(
    "Blog created successfully."
  );
  const [contentTextMode, setContentTextMode] = useState("plain");
  const [type, setType] = useState("success");

  const [snackState, setSnackState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = snackState;

  useEffect(() => {
    console.log("location?.state", location?.state);
    if (location?.state?.blog) {
      const { blog } = location?.state;
      updateBlog("whole_blog", {
        title: blog.title || "",
        author: blog.author !== "Anonymous" ? blog.author : "",
        description: blog.description !== "EMPTY" ? blog.description : "",
        content: blog.content?.replaceAll(`\\"`, `"`) || "",
        image_url: blog.imageURL !== "EMPTY" ? blog.imageURL : "",
        category: blog.category !== "EMPTY" ? blog.category : "",
        sub_category: blog.subcategory !== "EMPTY" ? blog.subcategory : "",
        tags: !blog.tags || blog.tags !== "EMPTY" ? blog.tags?.split(",") : [],
        createdAt: blog.createdAt ? blog.createdAt : "",
        url: blog.url,
      });
    }
  }, [location?.state]);

  const handleClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleStoreDynamo = async () => {
    if (process.env.NODE_ENV === "production") {
      await storeDraftBlog(
        title,
        author || "Team Ourlipi",
        description || "EMPTY",
        content,
        image_url || "EMPTY",
        category,
        sub_category,
        tags?.join(",") || "EMPTY",
        createdAt ? createdAt : ""
      );
    }
  };

  const handleGenerateBlog = async () => {
    const result = await createBlog({
      title,
      author,
      description,
      content,
      image_url,
      tags,
      category,
      sub_category,
      createdAt,
      url: editMode && url ? url : undefined,
    });
    console.log("result", result);
    let error = false;
    if (result.status !== 200 || result.error) {
      error = true;
      setType("failure");
      setResponseMessage(
        `Failed to create the blog! ${result.body?.resultMessage}`
      );
      console.error(result);
    }
    setSnackState({ ...snackState, open: true });
    setPreviewHtml("");
    if (!error) {
      setPreviewEnabled(false);
      setSubmitEnabled(false);
      const backendURL = getEnvVar("BACKEND_API_URL") || "";
      window.open(`${backendURL}/blog/${result?.body?.url}`);
      if (process.env.NODE_ENV === "production") {
        if (location?.state?.blog?.draftUrl) {
          const draft = await getDraftBlog(location?.state?.blog?.draftUrl);
          if (draft) {
            deleteDraftBlog(location?.state?.blog?.draftUrl);
          }
        }
      }
      updateBlog("whole_blog", {
        title: "",
        author: "",
        description: "",
        content: "",
        image_url: "",
        category: "",
        sub_category: "",
        tags: [],
      });
    }
  };

  const handleDraftBlog = async () => {
    let error = false;
    try {
      await handleStoreDynamo(true);
    } catch (e) {
      console.error(e);
      error = true;
      setType("failure");
      setResponseMessage("Failed to save the draft of  the blog!");
    }
    if (!error) {
      setPreviewEnabled(false);
      setSubmitEnabled(false);
      setResponseMessage("Draft saved successfully!");
    }
    setSnackState({ ...snackState, open: true });
    setTimeout(() => {
      if (!error) {
        navigate("/");
      }
    }, 3000);
  };

  useEffect(() => {
    async function enablePreview() {
      const htmlTemplateResult = await fetch("/htmlTemplate/blogTemplate.html");
      const htmlTemplate = await htmlTemplateResult.text();
      const html = generateHtml(htmlTemplate, {
        title,
        author,
        description,
        content,
        image_url,
        tags,
        category,
        sub_category,
        createdAt,
      });
      setPreviewHtml(html);
      setPreviewEnabled(true);
      setSubmitEnabled(true);
    }
    if (title.includes("?")) {
      setTitleError('Title should not have "?"');
      setPreviewEnabled(false);
      setSubmitEnabled(false);
      return;
    } else if (title.includes("',.:!@#$%&*^")) {
      setTitleError(
        "Be careful of adding symbols in title!! You can still continue to submit."
      );
    } else {
      setTitleError("");
    }
    if (title && content) {
      setTitleError("");
      enablePreview();
    }
  }, [
    title,
    author,
    description,
    content,
    image_url,
    tags,
    category,
    sub_category,
  ]);

  const handlePreview = () => {
    let child = window.open("about:blank", "htmlPreviewChild");
    child.document.write(previewHtml);
    child.document.close();
  };

  const handleCancel = () => {
    if (location?.state?.blog) {
      navigate(-1);
    } else {
      updateBlog("whole_blog", {
        title: "",
        author: "",
        description: "",
        content: "",
        image_url: "",
        category: "",
        sub_category: "",
        tags: [],
      });
    }
  };

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubcategory("");
    updateBlog("category", event.target.value);
  };

  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
    updateBlog("sub_category", event.target.value);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column" }}
          elevation={24}
        >
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            key={vertical + horizontal}
            autoHideDuration={3000}
            ContentProps={{
              classes: {
                root: {
                  background: "green",
                  color: "white",
                },
              },
            }}
          >
            <SnackbarContent
              message={responseMessage}
              style={{ background: type === "success" ? "#b3eda0" : "#f85252" }}
            />
          </Snackbar>
          <Grid container spacing={3}>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="blog-title" required>
                Title
              </FormLabel>
              <OutlinedInput
                id="blog-title"
                name="blog-title"
                type="name"
                placeholder="An awesome blog"
                autoComplete=""
                required
                value={title}
                onChange={(e) => updateBlog("title", e.target.value)}
              />
              <Typography mx={1} color={"red"}>
                {titleError}
              </Typography>
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="blog-author">Author</FormLabel>
              <OutlinedInput
                id="blog-author"
                name="blog-author"
                type="blog-author"
                placeholder="Defaults to 'Anonymous'"
                autoComplete="Anonymous"
                value={author}
                onChange={(e) => updateBlog("author", e.target.value)}
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="blog-description" required>
                Short Description
              </FormLabel>
              <OutlinedInput
                id="blog-description"
                name="blog-description"
                type="blog-description"
                placeholder="The blog is about so and so.."
                autoComplete=""
                required
                value={description}
                onChange={(e) => updateBlog("description", e.target.value)}
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", alignItems: "flex-end" }}
                >
                  <FormLabel htmlFor="blog-content">Blog content</FormLabel>
                </Grid>
                <Grid item xs={8}>
                  <ButtonGroup
                    aria-label="Basic button group"
                    sx={{ float: "right", mb: 1 }}
                    size="small"
                  >
                    <Button
                      onClick={() => setContentTextMode("plain")}
                      variant={
                        contentTextMode === "plain" ? "contained" : "outlined"
                      }
                    >
                      Plain Text
                    </Button>
                    <Button
                      onClick={() => setContentTextMode("rich")}
                      variant={
                        contentTextMode === "rich" ? "contained" : "outlined"
                      }
                    >
                      Rich Text
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              {contentTextMode === "rich" ? (
                <ReactQuill
                  modules={QUILL_MODULES}
                  formats={QUILL_FORMATS}
                  theme="snow"
                  value={content}
                  onChange={(value) => updateBlog("content", value)}
                  maxHeight={"500px"}
                  style={{
                    minHeight: "260px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                />
              ) : (
                <OutlinedInput
                  multiline
                  sx={{ maxHeight: "1000px", resize: "vertical", px: "0px" }}
                  minRows={10}
                  maxRows={20}
                  id="blog-content"
                  name="blog-content"
                  type="blog-content"
                  placeholder={loremIpsum}
                  value={content}
                  // dangerouslySetInnerHTML={{ __html: content }}
                  onChange={(e) => updateBlog("content", e.target.value)}
                />
              )}
            </FormGrid>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="blog-image">Image URL</FormLabel>
              <OutlinedInput
                required
                id="blog-image"
                name="blog-image"
                type="blog-image"
                placeholder="https://images.unsplash.com/photo-15424355..."
                value={image_url}
                onChange={(e) => updateBlog("image_url", e.target.value)}
              />
            </FormGrid>
            <FormGrid className="dropdown-container" item xs={12} md={6}>
              <FormLabel htmlFor="category" required>
                Category
              </FormLabel>
              <Select
                labelId="category-label"
                id="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Category"
              >
                <MenuItem value="">
                  <em>Select a category</em>
                </MenuItem>
                {Object.keys(data).map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormGrid>

            {selectedCategory && (
              <FormGrid className="dropdown-container" item xs={12} md={6}>
                <FormLabel htmlFor="sub-category" required>
                  Subcategory
                </FormLabel>
                <Select
                  labelId="subcategory-label"
                  id="subcategory"
                  value={selectedSubcategory}
                  onChange={handleSubcategoryChange}
                  label="Subcategory"
                >
                  <MenuItem value="">
                    <em>Select a subcategory</em>
                  </MenuItem>
                  {data[selectedCategory].map((subcategory) => (
                    <MenuItem key={subcategory} value={subcategory}>
                      {subcategory}
                    </MenuItem>
                  ))}
                </Select>
              </FormGrid>
            )}

            <FormGrid item xs={12}>
              <FormLabel htmlFor="tags">Tags</FormLabel>
              <Autocomplete
                sx={{ "& .MuiInputBase-root": { p: 0, maxHeight: "500px" } }}
                limitTags={20}
                freeSolo
                multiple
                id="tags"
                name="tags"
                type="tags"
                value={tags || []}
                options={[]}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <Tooltip title="Hit enter after each Tags" placement="top">
                    <TextField
                      {...params}
                      placeholder="TravelPhotography, SelfImprovement, GadgetReviews..."
                    />
                  </Tooltip>
                )}
                onChange={(e, value) => updateBlog("tags", value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option + index}
                      color="info"
                      variant="filled"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormGrid>
            <FormGrid item md={4} xs={12}>
              <FormControl sx={{ display: { md: "block", lg: "inline" } }}>
                <Button variant="outlined" color="error" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleDraftBlog}
                  disabled={!submitEnabled}
                  sx={{
                    marginLeft: { xs: 0, md: "10px" },
                    marginBottom: { xs: 3, md: 0 },
                  }}
                >
                  Save Draft
                </Button>
              </FormControl>
            </FormGrid>
            <FormGrid item md={8} xs={12}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handlePreview}
                  sx={{
                    marginRight: { xs: 0, md: "10px" },
                    marginBottom: { xs: 3, md: 0 },
                  }}
                  disabled={!previewEnabled}
                >
                  Preview
                </Button>
                <Button
                  variant="contained"
                  onClick={handleGenerateBlog}
                  disabled={!submitEnabled}
                >
                  {editMode ? "Update" : "Generate"} Blog
                </Button>
              </FormControl>
            </FormGrid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}
