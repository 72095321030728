import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormGrid } from "../BlogForm/BlogForm";
import { createSHA512Hash } from "../../javascript/hashingHelper";
import { hash } from "./constants";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage("loggedIn", "");
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn === hash) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const onButtonClick = () => {
    // Set initial error values to empty
    setEmailError("");
    setPasswordError("");

    // Check if the user has entered both fields correctly
    if ("" === email) {
      setEmailError("Please enter your email");
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Please enter a valid email");
      return;
    }

    if (email !== "blog_admin@blogger.com") {
      setLoginError("Invalid Email or Password!!");
      return;
    }

    if ("" === password) {
      setPasswordError("Please enter a password");
      return;
    }

    if (password.length < 7) {
      setPasswordError("The password must be 8 characters or longer");
      return;
    }

    try {
      if (createSHA512Hash(password) !== hash) {
        setLoginError("Invalid Email or Password!!");
        return;
      }
    } catch (e) {
      setLoginError("Invalid Email or Password!!");
      return;
    }

    setIsLoggedIn(hash);
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column" }}
          elevation={24}
          te
        >
          <Typography m={1} textAlign={"center"}>
            Login
          </Typography>
          <Grid container spacing={3} mt={1} justifyContent={"center"}>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="email" required>
                Email
              </FormLabel>
              <OutlinedInput
                id="emai"
                name="email"
                type="email"
                placeholder="Email"
                autoComplete=""
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Typography color={"#ED4337"}>{emailError}</Typography>
            </FormGrid>
          </Grid>
          <Grid container spacing={3} mt={1} justifyContent={"center"}>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <OutlinedInput
                required
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                autoComplete=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Typography color={"#ED4337"}>{passwordError}</Typography>
            </FormGrid>
          </Grid>
          <Grid container spacing={3} mt={1} justifyContent={"center"}>
            <FormGrid item xs={12} md={6}>
              <Typography color={"#ED4337"}>{loginError}</Typography>
            </FormGrid>
          </Grid>
          <FormGrid item xs={12} mt={2} textAlign={"center"}>
            <FormControl sx={{ display: "inline" }}>
              <Button variant="contained" onClick={onButtonClick}>
                Login
              </Button>
            </FormControl>
          </FormGrid>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
