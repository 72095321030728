import React, { useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { refrechServerCache } from "../../service/refrechServerCache";

export default function ControlCenter() {
  const [responseMessage, setResponseMessage] = useState(
    "Server cache refresh successful!"
  );
  const [type, setType] = useState("success");
  const [refreshing, setRefreshing] = useState(false);

  const [snackState, setSnackState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = snackState;

  const handleRefreshCache = async () => {
    setRefreshing(true);
    try {
      const response = await refrechServerCache();
      if (response?.error) {
        throw response?.error;
      } else {
        setSnackState({ ...snackState, open: true });
      }
    } catch (e) {
      setType("failure");
      setResponseMessage(e);
      setSnackState({ ...snackState, open: true });
    }
    setRefreshing(false);
  };

  const handleClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column" }}
          elevation={24}
        >
          <Grid container spacing={3} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Typography
                sx={{ textAlign: "center" }}
                fontSize={24}
                fontWeight={900}
              >
                Control Center
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Refresh Server Cache" />
                <CardContent>
                  <Button
                    variant="outlined"
                    onClick={handleRefreshCache}
                    disabled={refreshing}
                    startIcon={
                      <CachedIcon className={refreshing ? "spin" : ""} />
                    }
                  >
                    Refresh
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              onClose={handleClose}
              key={vertical + horizontal}
              autoHideDuration={type === "success" ? 5000 : 10000}
              ContentProps={{
                classes: {
                  root: {
                    background: "green",
                    color: "white",
                  },
                },
              }}
            >
              <SnackbarContent
                message={responseMessage}
                style={{
                  background: type === "success" ? "#b3eda0" : "#f85252",
                }}
              />
            </Snackbar>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}
