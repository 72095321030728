import { Box, Button, Container, Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./../../Theme/blogTheme.css";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="lg" sx={{ my: 1 }}>
        <Paper sx={{ display: "flex", flexDirection: "column" }} elevation={24}>
          <Box p={1}>
            <div class="card">
              <section class="page_404">
                <div class="container">
                  <div class="col-sm-12 ">
                    <div class="col-sm-10 col-sm-offset-1  text-center">
                      <div class="four_zero_four_bg">
                        <h1 class="text-center ">404</h1>
                      </div>
                      <div class="contant_box_404">
                        <h3 class="h2">Looks like you're lost!</h3>
                        <p>The page you are looking for is not avaible.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Box>
          <div className={"mainContainer"} style={{ height: "80%" }}>
            <Box className={"inputContainer"} m={2}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/");
                }}
              >
                Go Back
              </Button>
            </Box>
          </div>
        </Paper>
      </Container>
    </Box>
  );
};

export default NotFound;
