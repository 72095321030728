const { dynamoDocClient } = require("./dynamoClient");
const slugify = require("slugify");

async function storeDraftBlog(
    title,
    author,
    description,
    content,
    imageURL,
    category,
    subcategory,
    tags,
    createdAt,
) {

    const url = slugify(title, {
        replacement: '-',
        lower: true,
        strict: true,
        trim: true
    });
    const params = {
        TableName: "draft_blogs",
        Item: {
            url,
            title,
            author,
            description,
            content,
            imageURL,
            category,
            subcategory,
            tags,
            createdAt: createdAt ? createdAt : new Date().getTime().toString(),
            updatedAt: new Date().getTime().toString(),
        },
    };

    const data = await dynamoDocClient.put(params).promise();
    console.log("Dynamo DB Store Draft Blog - Success");
    console.log('Created Draft Blog in DynamoDB', title);
    return data;
}

async function getDraftBlog(url) {
    var params = {
        TableName: "draft_blogs",
        Key: { url },
    };
    const data = await dynamoDocClient.get(params).promise();
    console.log(data);
    console.log("Dynamo DB Get Draft Blog - Success", data.Item);
    return data.Item;
}

async function deleteDraftBlog(url) {
    var params = {
        TableName: "draft_blogs",
        Key: { url },
    };
    await dynamoDocClient.delete(params).promise();
    console.log("Dynamo DB Delete Draft Blog - Success");
}

async function listDraftBlogs(currentRows = [], lastItem) {
    let params = {
        TableName: "draft_blogs",
    };
    if (lastItem) {
        params.ExclusiveStartKey = { id: lastItem };
    }
    const data = await dynamoDocClient.scan(params).promise();
    console.log("Dynamo DB List Draft Blog - Success", data);
    currentRows = currentRows.concat(data.Items);
    if (data.LastEvaluatedKey) {
        return listDraftBlogs(currentRows, data.LastEvaluatedKey?.id);
    }
    return currentRows;
}


module.exports = {
    storeDraftBlog,
    getDraftBlog,
    listDraftBlogs,
    deleteDraftBlog,
};