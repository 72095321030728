import { getEnvVar } from "./envUtils";

export const createBlog = async ({
  title,
  author,
  description,
  content,
  image_url,
  tags,
  category,
  sub_category,
  url,
}) => {
  try {
    const backednURL = getEnvVar("BACKEND_API_URL");
    if (!backednURL) throw new Error("API details not found!!");

    const backendAPIKey = getEnvVar("BACKEND_API_KEY");
    if (!backendAPIKey) throw new Error("API key details not found!!");
    const CREATE_ENDPOINT = `${backednURL}/api/v1/blog/create`;
    const UPDATE_ENDPOINT = `${backednURL}/api/v1/blog/update`;
    const rawResponse = await fetch(url ? UPDATE_ENDPOINT : CREATE_ENDPOINT, {
      method: url ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        api_key: backendAPIKey,
      },
      body: JSON.stringify({
        title: title,
        content: content,
        category: category,
        subcategory: sub_category,
        imageURL: image_url,
        author: author,
        description: description,
        tags: (tags || []).join(","),
        url,
      }),
    });
    const body = await rawResponse.json();
    const status = rawResponse.status;

    // Kept the below line for future use to get error once backend changed
    // const result = await rawResponse.json();

    return { status, body, error: !!body.error };
  } catch (e) {
    console.error(e);
    return { error: true };
  }
};
