import * as React from "react";
import Layout from "./components/Layout";
import LoginLayout from "./components/LoginLayout";
import Login from "./Pages/Login/Login";
import NotFound from "./Pages/NotFound/NotFound";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BlogForm from "./Pages/BlogForm/BlogForm";
import ManageBlogs from "./Pages/ManageBlogs/ManageBlogs";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ControlCenter from "./Pages/ControlCenter/ControlCenter";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginLayout />}>
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<ControlCenter />} />
          <Route path="manage-blogs" element={<ManageBlogs />} />
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="create-blog" element={<BlogForm key="create" />} />
          <Route path="edit-blog" element={<BlogForm key="edit" editMode />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
