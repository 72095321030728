import { getEnvVar } from "../javascript/envUtils";

export const refrechServerCache = async () => {
  try {
    const backednURL = getEnvVar("BACKEND_API_URL");
    if (!backednURL) throw new Error("API details not found!!");

    const backendAPIKey = getEnvVar("BACKEND_API_KEY");
    if (!backendAPIKey) throw new Error("API key details not found!!");
    const REFRESH_CACHE_ENDPOINT = `${backednURL}/blog/cache/refresh`;
    const rawResponse = await fetch(REFRESH_CACHE_ENDPOINT, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        api_key: backendAPIKey,
      },
    });
    const body = await rawResponse.text();
    const status = rawResponse.status;

    if (body === "Success") return { status, body };
    else return { status, error: body };
  } catch (e) {
    console.error(e);
    return { error: "Failed to refresh server cache" };
  }
};
