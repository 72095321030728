var AWS = require("aws-sdk");
const { getEnvVar } = require("../javascript/envUtils");
AWS.config.update({
    "region": "ap-south-1",
    "accessKeyId": getEnvVar("AWS_ACCESS_KEY_ID"),
    "secretAccessKey": getEnvVar("AWS_SECRET_ACCESS_KEY")
});
var dynamoClient = new AWS.DynamoDB({ apiVersion: "2012-08-10" });
var dynamoDocClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2012-08-10" });

module.exports = {
    dynamoClient,
    dynamoDocClient
}